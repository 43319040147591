import styled from "styled-components"

import { IntegrationIcon } from "src/components/Integrations/IntegrationIcon"
import { isStaticIntegration } from "src/data/integrations/logic/integrations"
import {
  TIntegration,
  TIntegrationStatic,
} from "src/data/integrations/types/integrationTypes"
import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function IntegrationCard({
  integration,
}: {
  integration: TIntegration | TIntegrationStatic
}) {
  const linkTo = Routes.OrgIntegration.location(
    integration.integration_identifier
  ).pathname

  return (
    <StyledLink to={linkTo}>
      <Box>
        <IntegrationIcon
          icon={integration.icon}
          iconComponent={
            isStaticIntegration(integration) &&
            integration.static &&
            integration.iconComponent
          }
        />

        <MText variant="subtitle">{integration.name}</MText>
      </Box>
    </StyledLink>
  )
}

const Box = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
`

const StyledLink = styled(InternalLink)`
  height: 100%;
  width: 100%;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`
