import { useState } from "react"
import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { translateConnectionErrorKey } from "src/data/integrations/logic/integrations"
import { useIntegrationSecretMutation } from "src/data/integrations/queries/integrationAuthQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"
import { spacing } from "src/ui/spacing"

export function SecretDialog({
  integration,
  open,
  onClose,
  orgId,
  ownerType,
  reauthorize,
}: {
  integration: TIntegration
  open: boolean
  onClose: () => void
  orgId: string
  ownerType: OwnerType
  reauthorize?: boolean
}) {
  const { t, langKeys } = useTranslate()

  const integrationSecretMutation = useIntegrationSecretMutation({
    ownerType,
  })
  const [secret, setSecret] = useState("")

  // Temporary loading state, necessary because it can take some time for the
  // window to reload
  const [windowLoading, setWindowLoading] = useState(false)

  function handleClose() {
    onClose()
  }

  function handleConfirm() {
    if (!orgId) {
      // eslint-disable-next-line no-console
      console.error("No orgId in params")
      return
    }

    setWindowLoading(true)
    integrationSecretMutation.mutate(
      {
        secret,
        orgId,
        integration: integration.integration_identifier,
        reauthorize,
      },
      {
        // TODO WEB-XXX: Unless the BE starts returning useful data after the
        // setApiKey POST, we should
        // 1. start using react-query to cache integrations
        // 2. refetch all integrations after successful post
        //
        // This will be an improvement compared to reloading the window as we do
        // today
        onSuccess: () => {
          window.location.reload()
        },
        onError: () => {
          setWindowLoading(false)
        },
      }
    )
  }

  return (
    <ConfirmDialog
      title={t(langKeys.connect)}
      confirmLabel={t(langKeys.connect)}
      description={integration.auth.description}
      loading={integrationSecretMutation.isLoading || windowLoading}
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      error={
        integrationSecretMutation.error &&
        translateConnectionErrorKey({
          t,
          errorKey: integrationSecretMutation.error.response?.data?.error_key,
          integration,
        })
      }
    >
      <Content>
        <TextField
          fullWidth
          label={integration.auth.secret_description}
          value={secret}
          onChange={(e) => {
            integrationSecretMutation.reset()
            setSecret(e.target.value)
          }}
        />
      </Content>
    </ConfirmDialog>
  )
}

const Content = styled.div`
  padding: ${spacing.M} 0;
`
