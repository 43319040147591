import { Fragment, useMemo, useState } from "react"
import styled from "styled-components"

import { ListItemIcon, ListItemText } from "@material-ui/core"

import { LinkStatus } from "src/components/Integrations/Integration/LinkStatus"
import { ThermostatConfiguration } from "src/components/Integrations/Integration/ThermostatConfiguration"
import { ThermostatSettingsDialog } from "src/components/Integrations/IntegrationConnectDialogs/ThermostatSettingsDialog"
import { IntegrationIcon } from "src/components/Integrations/IntegrationIcon"
import { usePostThermostatSettingsEditIntiated } from "src/data/analytics/queries/integrationAnalyticsQueries"
import { DEFAULT_HOME_NAME, THome } from "src/data/homes/types/homeTypes"
import {
  checkBrokenIntegration,
  getCommonAvailableThermostatModes,
  hasSupportedHVACFeatures,
} from "src/data/integrations/logic/integrations"
import { useFetchIntegrationHomeLinks } from "src/data/integrations/queries/integrationLinkQueries"
import {
  OwnerType,
  TIntegration,
  TIntegrationEntities,
  TIntegrationHomeLink,
  TIntegrationHomeLinksSort,
  TThermostatSettings,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { mColors } from "src/ui/colors"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import InfoIcon from "src/ui/icons/info-i.svg"
import MinutLogo from "src/ui/icons/minut-icon.svg"
import EditIcon from "src/ui/icons/pen-outlined.svg"
import UnlinkIcon from "src/ui/icons/unlink.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

type IntegrationLinkTableData = {
  link: TIntegrationHomeLink
  entity: TIntegrationEntities[number] | undefined
  home: THome | undefined
}

export function LinkHomeTable({
  homes,
  integrationEntities,
  integration,
  homeLinks,
  loading,
  onLinkHome,
  onRemoveLink,
  sort,
  setSort,
}: {
  homes: THome[]
  integrationEntities: TIntegrationEntities
  integration: TIntegration
  homeLinks: TIntegrationHomeLink[]
  loading: boolean
  onLinkHome: ({
    homeName,
    homeId,
  }: {
    homeName: string
    homeId: string
  }) => void
  onRemoveLink: ({
    linkId,
    entityName,
    homeName,
  }: {
    linkId: string
    entityName: string
    homeName: string
  }) => void
  sort: TIntegrationHomeLinksSort
  setSort: (sort: TIntegrationHomeLinksSort) => void
}) {
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()

  const [homeId, setHomeId] = useState<string>("")
  const [thermostatSettings, setThermostatSettings] =
    useState<TThermostatSettings>()

  const postThermostatSettingsEditInitiated =
    usePostThermostatSettingsEditIntiated()

  const fetchIntegrationHomeLinks = useFetchIntegrationHomeLinks({
    integrationId: integration.integration_identifier,
    ownerId: orgId,
    ownerType: OwnerType.ORGANIZATION,
    filters: {
      limit: 1,
      link_status: "linked",
    },
  })

  const nbrLinkedHomes = fetchIntegrationHomeLinks.data?.paging.total_count ?? 0

  const trackIntegration = {
    [integration.integration_identifier]: {
      linked_homes: nbrLinkedHomes,
    },
  }

  function isBrokenLink(data: IntegrationLinkTableData) {
    return data.link.link_status === "broken"
  }

  function isLinked(data: IntegrationLinkTableData) {
    return !!data.entity && data.link.link_status !== "unlinked"
  }

  function commonAvailableModes(data: IntegrationLinkTableData) {
    return getCommonAvailableThermostatModes(data.entity?.devices || [])
  }

  const supportsHVACFeatures = hasSupportedHVACFeatures(integration)

  const isBrokenIntegration = checkBrokenIntegration(integration)

  const columns: TableColumn<IntegrationLinkTableData>[] = [
    {
      value: "name",
      label: t(langKeys.minut_home),
      renderLabel: (label) => (
        <HeaderBox>
          <IntegrationIcon iconComponent={<MinutLogo />} size={24} />
          {label}
        </HeaderBox>
      ),
      hidden: false,
      enableSort: true,
      sortButtonSpacing: "compact",
      render: (data) => {
        return (
          <MText variant="subtitle" key={data.link.link_id}>
            <InternalLink to={Routes.Home.location(data.link.home_id)}>
              {data.link.home_name}
            </InternalLink>
          </MText>
        )
      },
      columnWidth: "auto",
    },
    {
      value: "entity-home",
      label: t(langKeys.integrations_integration_home, {
        name: integration.name,
      }),
      renderLabel: (label) => (
        <HeaderBox>
          <IntegrationIcon icon={integration.icon} size={24} />
          {label}
        </HeaderBox>
      ),
      hidden: false,
      render: (data) => {
        return (
          <EntityColumn
            linked={isLinked(data)}
            entityName={data.entity?.name}
            link={data.link}
            onLinkHome={onLinkHome}
            isBrokenLink={isBrokenLink(data)}
            isBrokenIntegration={isBrokenIntegration}
          />
        )
      },
      columnWidth: "1fr",
    },
    {
      value: "devices",
      label: t(langKeys.sensor_plural),
      hidden: !supportsHVACFeatures,
      render: (data) => {
        return (
          <div>
            {t(langKeys.integration_thermostat_device_count, {
              count: data.entity?.devices?.length ?? 0,
            })}
          </div>
        )
      },
      columnWidth: "1fr",
    },
    {
      value: "link_status",
      label: t(langKeys.status),
      hidden: false,
      enableSort: true,
      sortButtonSpacing: "compact",
      render: (data) => {
        const devices = data.entity?.devices || []

        return (
          <LinkStatus
            devices={devices}
            hasSupportedHVACFeatures={supportsHVACFeatures}
            linkStatus={data.link.link_status}
            isBrokenIntegration={isBrokenIntegration}
          />
        )
      },
      columnWidth: "1fr",
    },
    {
      value: "thermostat-mode-header",
      label: t(langKeys.integrations_turn_thermostat_to_title),
      renderLabel: (label) => (
        <ThermostatModeHeader>
          {label}
          {":"}
          <MTooltip title={t(langKeys.integrations_turn_thermostat_to_tooltip)}>
            <InfoIcon fill="currentColor" width={18} />
          </MTooltip>
        </ThermostatModeHeader>
      ),
      hidden: !supportsHVACFeatures,
      render: (data) => {
        return (
          <ThermostatConfiguration
            hidden={!supportsHVACFeatures}
            commonAvailableModes={commonAvailableModes(data)}
            linked={isLinked(data)}
            home={data.home}
          />
        )
      },
      columnWidth: "1fr",
    },
    {
      value: "more-button",
      label: "",
      hidden: false,
      columnWidth: "auto",
      render: (data) => {
        return (
          <div>
            <MoreButton
              menuItems={[
                {
                  key: "edit-thermostat-settings",
                  contents: (
                    <>
                      <ListItemIcon>
                        <EditIcon height="24" width="24" />
                      </ListItemIcon>
                      <LisItemUnlinkText
                        primary={t(
                          langKeys.integrations_thermostat_edit_thermostat_settings
                        )}
                      />
                    </>
                  ),
                  onClick: () => {
                    setThermostatSettings({
                      mode: data.home?.thermostat_mode_on_checkin,
                      commonAvailableModes: commonAvailableModes(data),
                      turnOnTime:
                        data.home?.thermostat_turn_on_in_minutes_before_checkin,
                      setPoints:
                        data.home?.thermostat_temperature_setpoint_on_checkin,
                    })

                    setHomeId(data.home?.home_id || "")
                    postThermostatSettingsEditInitiated.mutate({
                      integrations: trackIntegration,
                    })
                  },
                  hidden: !isLinked(data) || !supportsHVACFeatures,
                },
                {
                  key: "unlink-home",
                  contents: (
                    <>
                      <ListItemIcon>
                        <StyledUnlinkIcon height="24" width="24" />
                      </ListItemIcon>
                      <LisItemUnlinkText
                        primary={t(langKeys.integrations_link_remove)}
                      />
                    </>
                  ),
                  onClick() {
                    if (!data.link.link_id || !data.entity) return

                    onRemoveLink({
                      linkId: data.link.link_id,
                      entityName: data.entity.name,
                      homeName:
                        data.link.home_name ||
                        DEFAULT_HOME_NAME(data.link.home_id),
                    })
                  },
                  hidden: !isLinked(data),
                },
              ].filter((item) => !item.hidden)}
              disabled={!isLinked(data)}
            />
          </div>
        )
      },
    },
  ]

  const tableData: IntegrationLinkTableData[] = useMemo(() => {
    return homeLinks.map((link) => ({
      link,
      entity: integrationEntities.find(
        (entity) => entity.entity_id === link.entity_id
      ),
      home: homes.find((home) => home.home_id === link.home_id),
    }))
  }, [homeLinks, homes, integrationEntities])

  const { rows, headerElements, templateColumns } =
    useTableColumns<IntegrationLinkTableData>({
      columns,
      data: tableData,
      sort,
      onSortChange: (sort) => {
        const id = sort.id
        if (id === "name" || id === "link_status") {
          setSort({ id, order: sort.order })
        }
      },
    })

  return (
    <div>
      <GridTable
        header={headerElements}
        rows={
          loading ? skeletonRows(supportsHVACFeatures ? 6 : 4) : (rows ?? [])
        }
        templateColumns={templateColumns}
        useFallbackResponsiveMode
      />

      {thermostatSettings && (
        <ThermostatSettingsDialog
          open={true}
          onClose={() => setThermostatSettings(undefined)}
          thermostatSettings={thermostatSettings}
          homeId={homeId}
          trackIntegration={trackIntegration}
        />
      )}
    </div>
  )
}

function skeletonRows(numOfColumns: 4 | 6) {
  const arrayFromColumns = Array.from({ length: numOfColumns - 1 })

  const createSkeletonRow = (index: number) => (
    <Fragment key={"row-" + index + numOfColumns}>
      {arrayFromColumns.map((_, index2) => (
        <div key={index2 + numOfColumns}>
          <MSkeleton
            variant="text"
            width={`${Math.floor(Math.random() * 40) + 60}%`}
          />
        </div>
      ))}

      <div>
        <MoreButton disabled={true} />
      </div>
    </Fragment>
  )

  return Array.from(Array(4), (_, index) => createSkeletonRow(index))
}

function EntityColumn({
  linked,
  entityName,
  link,
  isBrokenLink,
  isBrokenIntegration,
  onLinkHome,
}: {
  linked: boolean
  entityName?: string
  link: TIntegrationHomeLink
  isBrokenLink: boolean
  isBrokenIntegration: boolean
  onLinkHome: ({
    homeId,
    homeName,
  }: {
    homeId: string
    homeName: string
  }) => void
}) {
  const { t, langKeys } = useTranslate()

  if (linked && !!entityName) {
    return <MText variant="subtitle">{entityName}</MText>
  }

  if (isBrokenLink) {
    return (
      <div>
        <StyledTextButton
          onClick={() =>
            onLinkHome({
              homeId: link.home_id,
              homeName: link.home_name || DEFAULT_HOME_NAME(link.home_id),
            })
          }
          disabled={isBrokenIntegration}
        >
          {t(langKeys.integrations_home_link_relink_action)}
        </StyledTextButton>
      </div>
    )
  }

  return (
    <div>
      <LinkButton
        variant="subtle"
        size="small"
        onClick={() =>
          onLinkHome({
            homeId: link.home_id,
            homeName: link.home_name || DEFAULT_HOME_NAME(link.home_id),
          })
        }
        disabled={isBrokenIntegration}
      >
        {t(langKeys.integrations_link_home)}
      </LinkButton>
    </div>
  )
}

const LinkButton = styled(MButton)`
  margin: auto auto auto 0;
`

const StyledUnlinkIcon = styled(UnlinkIcon)`
  color: ${mColors.textPrimary};
`

const LisItemUnlinkText = styled(ListItemText)`
  color: ${mColors.textPrimary};
`

const ThermostatModeHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.XS2};
`

const StyledTextButton = styled(TextButton)`
  width: max-content;
`
const HeaderBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`
