import { useState } from "react"
import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { translateConnectionErrorKey } from "src/data/integrations/logic/integrations"
import { useClientCredentialsMutation } from "src/data/integrations/queries/integrationAuthQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"
import { spacing } from "src/ui/spacing"

export function ClientCredentialDialog({
  integration,
  open,
  onClose,
  orgId,
  ownerType,
  reauthorize,
}: {
  integration: TIntegration
  open: boolean
  onClose: () => void
  orgId: string
  ownerType: OwnerType
  reauthorize?: boolean
}) {
  const { t } = useTranslate()
  const clientCredMutation = useClientCredentialsMutation({ ownerType })
  const [clientSecret, setSecret] = useState("")
  const [clientId, setClientId] = useState("")

  // Temporary loading state, necessary because it can take some time for the
  // window to reload
  const [windowLoading, setWindowLoading] = useState(false)

  function handleClose() {
    onClose()
  }

  function handleConfirm() {
    setWindowLoading(true)
    clientCredMutation.mutate(
      {
        clientSecret,
        clientId,
        orgId,
        integration: integration.integration_identifier,
        reauthorize,
      },
      {
        // TODO WEB-582: We should
        // 1. start using react-query to cache integrations
        // 2. refetch all integrations after successful post
        //
        // This will be an improvement compared to reloading the window as we do
        // today
        onSuccess: () => {
          window.location.reload()
        },
        onError: () => {
          setWindowLoading(false)
        },
      }
    )
  }

  return (
    <ConfirmDialog
      title={t(langKeys.connect)}
      confirmLabel={t(langKeys.connect)}
      description={integration.auth.description}
      loading={clientCredMutation.isLoading || windowLoading}
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmButtonProps={{ disabled: !clientSecret || !clientId }}
      error={
        clientCredMutation.error &&
        translateConnectionErrorKey({
          t,
          errorKey: clientCredMutation.error.response?.data?.error_key,
          integration,
        })
      }
    >
      <Content>
        <TextField
          fullWidth
          label={`Client id`}
          value={clientId}
          onChange={(e) => {
            clientCredMutation.reset()
            setClientId(e.target.value)
          }}
        />

        <TextField
          fullWidth
          label={`Client secret`}
          value={clientSecret}
          onChange={(e) => {
            clientCredMutation.reset()
            setSecret(e.target.value)
          }}
        />
      </Content>
    </ConfirmDialog>
  )
}

const Content = styled.div`
  padding: ${spacing.M} 0;
  display: grid;
  grid-gap: ${spacing.M};
`
