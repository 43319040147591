import { useState } from "react"
import styled from "styled-components"

import {
  DragDropArea,
  UploadStatus,
} from "src/components/Integrations/StaticIntegrations/GuestImport/DragDropArea"
import { FileRequirementsSection } from "src/components/Integrations/StaticIntegrations/GuestImport/FileRequirementsSection"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { BackButton } from "src/ui/BackButton/BackButton"
import { palette } from "src/ui/colors"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { DefaultBox } from "src/ui/Layout/DefaultBox"
import { MainView } from "src/ui/Layout/MainView"
import { PageSubtitle } from "src/ui/Layout/PageSubtitle"
import { PageTitle } from "src/ui/Layout/PageTitle"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import GuestImportIcon from "./guest-import.svg"

export function GuestImport() {
  const { t, langKeys } = useTranslate()
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>()

  return (
    <div>
      <BackButton to={Routes.OrgIntegrations.location()}>
        {t(langKeys.back)}
      </BackButton>

      <MainView
        metaTitle="guest-importer"
        titleBarProps={{
          showBottomBorder: true,
        }}
        title={
          <Top>
            <IconBox>
              <GuestImportIcon />
            </IconBox>

            <div>
              <PageTitle>Guest Importer</PageTitle>
              <PageSubtitle>Import guests from a file</PageSubtitle>
            </div>
          </Top>
        }
      >
        <DefaultBox>
          <Content>
            <div>
              <MText variant="heading3">Import guests</MText>
              <MText variant="bodyS" color="secondary">
                Import guests directly from a CSV file by using the file
                uploader below. This is useful if the Property Management System
                you're using does not yet have a dedicated integration with
                Minut.
              </MText>
            </div>

            <StyledDragDropArea setUploadStatus={setUploadStatus} />

            {uploadStatus?.importedGuests && (
              <MBanner type="good">
                Successfully imported {uploadStatus.importedGuests} guests
              </MBanner>
            )}

            {uploadStatus?.authError && (
              <MBanner type="error">{uploadStatus.authError.message}</MBanner>
            )}

            {uploadStatus?.error && (
              <MBanner type="error">{uploadStatus.error.message}</MBanner>
            )}

            {uploadStatus?.rowErrors && !!uploadStatus.rowErrors.length && (
              <MBanner type="error">
                <div>The imported file had following errors</div>

                <ErrorList>
                  {uploadStatus.rowErrors.map((row) => (
                    <ErrorListItem key={row.row}>
                      Row {row.row}: {row.error}
                    </ErrorListItem>
                  ))}
                </ErrorList>
              </MBanner>
            )}

            <InfoBox
              title="Note: All previously CSV-imported guests will be removed"
              type="warning"
            >
              To handle existing guests that may have been updated, all guests
              that have been imported from a csv-file will be removed and
              replaced with the information from the new file.
            </InfoBox>

            <FileRequirementsSection />
          </Content>
        </DefaultBox>
      </MainView>
    </div>
  )
}

const Content = styled.div`
  display: grid;
  gap: ${spacing.M};
  padding-bottom: ${spacing.S};
`

const Top = styled.div`
  display: flex;
`

const IconBox = styled.div`
  display: flex;
  height: 65px;
  width: 65px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  background: ${palette.rumah};
  margin-right: ${spacing.XL};
`

const StyledDragDropArea = styled(DragDropArea)`
  margin: ${spacing.XS} 0;
`

const ErrorList = styled.ul`
  padding-left: 0;
  list-style-type: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
`

const ErrorListItem = styled.li`
  margin-bottom: ${spacing.S};
  padding-right: ${spacing.S};
  padding-left: 1.9em;
`
