import styled from "styled-components"

import { List, ListItem } from "@material-ui/core"

import { backgroundGrayV2 } from "src/ui/colors"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { GridTable } from "src/ui/GridTable/GridTable"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { MLink } from "src/ui/Link/MLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function FileRequirementsSection() {
  const headers = ["Header", "Description"].map((h, i) => (
    <div key={i}>{h}</div>
  ))
  const rows = [
    <>
      <HeaderColumn>
        <FormattedVariable>Home name</FormattedVariable>
      </HeaderColumn>
      <Description>
        <DescriptionText>
          <p>
            The name of the home. Must match with one of the home names on your
            account. "Property", "Property name" and "Unit name" are also
            allowed.
          </p>
          <MBanner size="small">Required</MBanner>
        </DescriptionText>
      </Description>
    </>,
    <>
      <HeaderColumn>
        <FormattedVariable>Guest name</FormattedVariable>
      </HeaderColumn>
      <Description>
        <DescriptionText>
          <p>
            The full name of the guest checking in. "Tenant name" is also
            allowed.
          </p>
          <MBanner size="small">Required</MBanner> if{" "}
          <FormattedVariable>First name</FormattedVariable> and{" "}
          <FormattedVariable>Last name</FormattedVariable> are not passed.
        </DescriptionText>
      </Description>
    </>,
    <>
      <HeaderColumn>
        <FormattedVariable>First name</FormattedVariable>
      </HeaderColumn>
      <Description>
        <DescriptionText>
          <p>
            The first name of the guest checking in. "Tenant first name" is also
            allowed.
          </p>
          <MBanner size="small">Required</MBanner> if{" "}
          <FormattedVariable>Guest name</FormattedVariable> is not passed.
        </DescriptionText>
      </Description>
    </>,
    <>
      <HeaderColumn>
        <FormattedVariable>Last name</FormattedVariable>
      </HeaderColumn>
      <Description>
        <DescriptionText>
          <p>
            The full name of the guest checking in. "Tenant last name" is also
            allowed.
          </p>
          <MBanner size="small">Required</MBanner> if{" "}
          <FormattedVariable>Guest name</FormattedVariable> is not passed.
        </DescriptionText>
      </Description>
    </>,
    <>
      <HeaderColumn>
        <FormattedVariable>Check in</FormattedVariable>
      </HeaderColumn>
      <Description>
        <DescriptionText>
          <p>
            The check-in date and time of the guest. "Arrive" is also allowed.
            If no time is given it will default to 15:00 on the day of check-in.
            See below for a list of supported date formats.{" "}
            <MLink href={`#${supportedDateFormatsAnchorTag}`}>Learn more</MLink>
            .
          </p>

          <MBanner size="small">Required</MBanner>
        </DescriptionText>
      </Description>
    </>,
    <>
      <HeaderColumn>
        <FormattedVariable>Check out</FormattedVariable>
      </HeaderColumn>
      <Description>
        <DescriptionText>
          <p>
            The check-out date and time of the guest. "Depart" is also allowed.
            If no time is given it will default to 11:00 on the day of
            check-out. See below for a list of supported date formats.{" "}
            <MLink href={`#${supportedDateFormatsAnchorTag}`}>Learn more</MLink>
            .
          </p>
          <MBanner size="small">Required</MBanner>
        </DescriptionText>
      </Description>
    </>,
    <>
      <HeaderColumn>
        <FormattedVariable>Phone number</FormattedVariable>
      </HeaderColumn>
      <Description>
        <DescriptionText>
          <p>
            The phone number of the guest. Will be used for guest communication.
            "Phone" and "Tenant number" are also allowed. The phone number must
            be in international format for the messaging service provider to be
            able to send the message to the customer. If it is not, the importer
            will do it's best to try to transform it into international format.
          </p>

          <StyledInfoBox
            type="info"
            title="About international phone number format"
          >
            A phone number in full international format includes a plus sign (+)
            followed by the country code, city code, and local phone number. For
            example, if a guest in the United States (country code "1") has the
            area code "408" and phone number "XXX-XXXX", you'd enter +1408 XXX
            XXXX.
          </StyledInfoBox>
          <MBanner size="small">Required</MBanner>
        </DescriptionText>
      </Description>
    </>,
    <>
      <HeaderColumn>
        <FormattedVariable>Email</FormattedVariable>
      </HeaderColumn>
      <Description>
        <DescriptionText>
          <p>The email of the guest. Will be used for guest communication.</p>
        </DescriptionText>
      </Description>
    </>,
  ]

  return (
    <ExpandableSection title="File Requirements">
      <Box>
        <MText variant="body">
          The file must be a CSV file. The importer is case insensitive, special
          characters are ignored, spaces are stripped, trimmed and ignored.
          Extra columns are also ignored.
        </MText>

        <div>
          <StyledTitleText variant="subtitleS">
            Supported columns
          </StyledTitleText>
          <GridTable
            templateColumns="1fr 3fr"
            header={headers}
            rows={rows}
            useFallbackResponsiveMode
          />
        </div>

        <div id={supportedDateFormatsAnchorTag}>
          <StyledTitleText variant="subtitleS">
            Supported date and time formats
          </StyledTitleText>
          <MText variant="body">
            The guest importer supports the following date and time formats.
          </MText>
          <List>
            {supportedDateFormats.map((d) => (
              <ListItem key={d}>
                <FormattedVariable>{d}</FormattedVariable>
              </ListItem>
            ))}
          </List>
        </div>
      </Box>
    </ExpandableSection>
  )
}

const supportedDateFormatsAnchorTag = "supported-date-formats"

const supportedDateFormats = [
  "YYYY-MM-DD",
  "YYYY-MM-DD HH:MM",
  "YYYY-MM-DD HH:MM:SS",
  "YYYY-MM-DD:HH:MM",
  "YYYY-MM-DD:HH:MM:SS",
  "MM-DD-YYYY",
  "MM-DD-YYYY HH:MM",
  "MM-DD-YYYY HH:MM:SS",
  "MM/DD/YYYY",
  "MM/DD/YYYY HH:MM",
  "MM/DD/YYYY HH:MM:SS",
]

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL};
  padding-block: ${spacing.M};
`

const StyledTitleText = styled(MText)`
  margin-bottom: ${spacing.M};
`

const HeaderColumn = styled.div`
  display: block;
`

const FormattedVariable = styled.div`
  padding: 0 ${spacing.XS2};
  background: ${backgroundGrayV2};
  border-radius: 4px;
  display: inline-block;
`

const Description = styled.div`
  display: block;

  p {
    padding: ${spacing.XS2} 0;
    margin: ${spacing.XS2} 0;
  }

  p:first-child {
    margin-top: 0;
    padding-top: 0;
  }
`

function DescriptionText({ children }: { children: React.ReactNode }) {
  return <MText variant="body">{children}</MText>
}

const StyledInfoBox = styled(InfoBox)`
  margin: ${spacing.M} 0;
`
