import { useState } from "react"
import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { translateConnectionErrorKey } from "src/data/integrations/logic/integrations"
import { usePostIntegrationMultiSecret } from "src/data/integrations/queries/integrationAuthQueries"
import {
  INTEGRATION_SECRET_DEFAULT,
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"
import { spacing } from "src/ui/spacing"

export function MultiSecretDialog({
  integration,
  open,
  onClose,
  orgId,
  ownerType,
  reauthorize,
}: {
  integration: TIntegration
  open: boolean
  onClose: () => void
  orgId: string
  ownerType: OwnerType
  reauthorize?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const postIntegrationMultiSecret = usePostIntegrationMultiSecret({
    ownerType,
  })

  const [secret, setSecret] = useState<Map<string, string>>(
    new Map(
      integration.auth.multi_secret?.map((secret) => [
        secret?.id ?? INTEGRATION_SECRET_DEFAULT,
        "",
      ])
    )
  )

  // Temporary loading state, necessary because it can take some time for the
  // window to reload
  const [windowLoading, setWindowLoading] = useState(false)

  function handleClose() {
    onClose()
  }

  function handleConfirm() {
    if (!orgId) {
      // eslint-disable-next-line no-console
      console.error("No orgId in params")
      return
    }
    const multiSecret = Array.from(secret).map(([id, secret]) => ({
      id,
      secret,
    }))
    setWindowLoading(true)
    postIntegrationMultiSecret.mutate(
      {
        secrets: multiSecret,
        orgId,
        integration: integration.integration_identifier,
        reauthorize,
      },
      {
        // todo web-xxx: unless the be starts returning useful data after the
        // setapikey post, we should
        // 1. start using react-query to cache integrations
        // 2. refetch all integrations after successful post
        //
        // this will be an improvement compared to reloading the window as we do
        // today
        onSuccess: () => {
          window.location.reload()
        },
        onError: () => {
          setWindowLoading(false)
        },
      }
    )
  }

  function handleSecretValue(id: string, value: string) {
    setSecret((prevState) => {
      prevState.set(id, value)
      return new Map(prevState)
    })
  }

  return (
    <ConfirmDialog
      title={t(langKeys.connect)}
      confirmLabel={t(langKeys.connect)}
      description={integration.auth.description}
      loading={postIntegrationMultiSecret.isLoading || windowLoading}
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      error={
        postIntegrationMultiSecret.isError &&
        translateConnectionErrorKey({
          t,
          errorKey: postIntegrationMultiSecret.error.response?.data?.error_key,
          integration,
        })
      }
    >
      <Content>
        {integration.auth.multi_secret?.map(({ id, description }) => (
          <TextField
            key={id}
            fullWidth
            label={description}
            value={secret.get(id ?? INTEGRATION_SECRET_DEFAULT)}
            onChange={(e) => {
              postIntegrationMultiSecret.reset()
              handleSecretValue(
                id ?? INTEGRATION_SECRET_DEFAULT,
                e.target.value
              )
            }}
          />
        ))}
      </Content>
    </ConfirmDialog>
  )
}

const Content = styled.div`
  margin: ${spacing.S} 0 ${spacing.M} 0;
  display: grid;
  gap: ${spacing.M};
`
